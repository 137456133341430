import Breadcrumb from "./Breadcrumb";
import SmallBanner from "./SmallBanner";

const Banner = () => {
  return (
    <SmallBanner title="All Projects">
      {/* Breadcrumb */}
      <Breadcrumb
        breadcrumbs={[
          ["Home", "/"],
          ["All Projects", "/"],
        ]}
      />
    </SmallBanner>
  );
};

export default Banner;
