import { useEffect, useState } from "react";

const PopularTags = ({ TagsList }) => {
  const [tagArr, setTagArr] = useState([])
  useEffect(() => {
    setTagArr(TagsList.split(","))
  }, [])
  return (
    <>
      <h5 className="title">Popular Tags</h5>
      <ul className="popular__tag">
        {tagArr.map((item,index) => (
          <li key={index}>
            <button className="cmn--btn m-4">
              <span>
                {item}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PopularTags;
