const openai = require('../config/openaiConfig')

export const generateMeta = async (text,maxToken=1000) => {

  const description = await openai.createChatCompletion({
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: 'user',
        content:text
      }
    ],
    max_tokens: maxToken
  })


return description.data.choices[0].message.content;


}

export const generateImage = async (prompt,maxImage=1) => {

  const image = await openai.createImage({
    prompt: prompt,
    n: maxImage,
    size: '1024x1024'
  })

  const url=image.data.data[0].url;
  return url
  
}

//module.exports = { generateMeta, generateImage }