import { useState } from "react";
import { generateMeta } from '../../API/controllers/openaiController'
import Preloader from '../preloader/Preloader'

import { useAuthContext } from "../../hooks/useAuthContext";
import { useWord } from '../../hooks/useWord'

import { useFirestore } from "../../hooks/useFirestore";
const InputText = () => {
  const [showError, setShowError] = useState(false);
  const [pre, setPre] = useState(false);

  const [textInput, setTextInput] = useState("");

  const [outInput, setOutInput] = useState("");

  const [countRows, setCountRows] = useState(4);

  const [indexSelect, setIndexSelect] = useState(0);

  const [listSelect, setListSelect] = useState(["صحح النص التالي لغوياً", "صحح النص التالي لغوياً مع اضافة الحركات مثل الضمه والكسره والفتحه ..الخ", "صحح كلام النص بما يتناسب مع المنطق", "صحح كلام النص بما يتناسب مع المنطق مع اضافة الحركات مثل الضمه والكسره والفتحه ..الخ"])

  const { wordsIn, wordsOut, costIn, costOut, costAll, user } = useAuthContext();

  const { EditWordOut, EditWordIn, EditAllCost, EditCostIn, EditCostOut } = useWord(wordsIn, wordsOut, costIn, costOut, costAll);
  const { editDocument } = useFirestore("Users")
  const [wordsLength, setWordsLength] = useState(0)
  const handleChange = (event) => {
    let value = event.target.value.replace("  ", " ");
    const words = value.trim().split(' ');
    setWordsLength(words.length)

    if (words.length <= 750) {
      setTextInput(value);
      setShowError(false);
    } else {
      setShowError(true);
    }
    const Line = value.trim().split('\n');
    if (Line.length > 4) {
      setCountRows(Line.length);
    } else {
      setCountRows(4);
    }

  };

  const SelectFun = (e) => {
    console.log(listSelect[e.target.value]);
    setIndexSelect(e.target.value);
  }
  return (
    <>
      <section className="banner__section banner__section__four  bannerbg" dir="rtl">
        <div className="container">
          <div className="banner__wrapper">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="banner__content">
                  <div className="content__box text-white pb-5">
                    {pre && <Preloader alowTimer={false} />}
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={SelectFun}>
                      <option value={0} selected>تعديل الكلام إملائياً فقط</option>
                      <option value={1}>تعديل الكلام إملائيًا مع إضافة الحركات</option>
                      <option value={2}>تعديل محتوى الكلام وإملائيته فقط</option>
                      <option value={3}>تعديل محتوى الكلام وإملائيته مع إضافة الحركات</option>
                    </select>
                    <div className="form-outline">
                      <label className="form-label" htmlFor="textAreaExample">أدخِلْ ما تريدُ لتُصَحِّحَ اللغةَ</label>

                      <textarea onChange={handleChange} className="form-control" id="textAreaExample1" rows={countRows}></textarea>
                    </div>

                    <button className="cmn--btn" type="submit" onClick={async () => {
                      setPre(true)
                      const str = listSelect[indexSelect] + "(" + textInput + ")";
                      let val = await generateMeta(str)
                      setOutInput(val)

                      const words2 = val.trim().split(' ');

                      await EditWordIn(wordsLength)
                      await EditWordOut(words2.length)
                      let costInNow = ((((1000 * wordsLength) / 750) * 0.004) / 10000)
                      let costOutNow = ((((1000 * words2.length) / 750) * 0.004) / 10000)
                      let total = costInNow + costOutNow;
                      console.log(user.uid)
                      console.log(costOutNow)
                      console.log(total)

                      await EditAllCost(total)
                      await EditCostIn(costInNow)
                      await EditCostOut(costOutNow)
                      await editDocument(user.uid, { wordsOut: (words2.length + wordsOut), wordsIn: (wordsLength + wordsIn), costOut: (costOutNow + costOut), costIn: (costInNow + costIn), costAll: (total + costAll) })

                      setPre(false)
                    }}>
                      <span>صحح</span>
                    </button>
                    {showError &&
                      <div className="typography__items">

                        <h5 className="text-danger">
                          اقصى حد هو 750 كلمه يجب ان تطور حسابك اذا كنت تريد اكثر من ذلك
                        </h5>
                      </div>

                    }
                    <div className="form-outline">
                      <label className="form-label" htmlFor="textAreaExample"> الناتِجُ</label>

                      <textarea disabled className="form-control" id="textAreaExample1" rows={countRows} value={outInput}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default InputText;
