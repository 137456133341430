import { Link} from 'react-router-dom'
import box_element from "../../public/img/elements/box-element.png";
import logo from "../../public/img/logo/logo.png";
import facebook from "../../public/img/svg-icon/facebook.svg";
import instagram from "../../public/img/svg-icon/instagram.svg";

const FooterFour = () => {
  return (
    <footer className="footer__section footer__section__four">
      {/* container */}
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__top">
            <div className="row g-5">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer__widget">
                  <div className="widget__head">
                    <Link to="/" className="footer__logo">
                      <img src={logo} alt="logo" className="img-fluid" />
                    </Link>
                  </div>
                  <p className="pb__20">
                    Artificial Intelligence (AI) and Machine Learning (ML) are
                    closely related technologies that enable computers to learn
                    from data and make predictions
                  </p>
                  <ul className="social">
                    <li>
                      <a href="https://www.facebook.com/BoneStudioSM" className="social__item">
                        <span className="icon">
                          <img src={facebook} alt="svg" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/bone.studiosm/" className="social__item social__itemtwo">
                        <span className="icon">
                          <img
                            src={instagram}
                            alt="svg"
                            className="img-fluid"
                          />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer__widget">
                  <div className="widget__head">
                    <h4>Quick Links</h4>
                  </div>
                  <div className="widget__link">
                  <Link to="/" className="link">
                      Home
                    </Link>
                    <Link to="/about" className="link">
                      About us
                    </Link>
                    <Link to="/pricing" className="link">
                    pricing
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer__widget">
                  <div className="widget__head">
                    <h4>products</h4>
                  </div>
                
                  <div className="widget__link">
                    <Link to="/Edit" className="link">
                    Edit Word in arabic
                    </Link>
                    <Link to="/Transmomy" className="link">
                    mother of translator
                    </Link>
                    <Link to="/Math" className="link">
                    FAST Math Solutions
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer__widget">
                  <div className="widget__head">
                    <h4>Contact</h4>
                  </div>
                  <div className="widget__link">
                   
                    <Link to="/" className="footer__contact__items">
                      <span className="icon icontwo">
                        <i className="material-symbols-outlined">
                          mark_as_unread
                        </i>
                      </span>
                      <span className="fcontact__content">
                      bonestudiocontact@gmail.com
                      </span>
                    </Link>
                    <a href="https://bonestudio.online/" className="footer__contact__items">
                      <span className="icon iconthree">
                        <span className="material-symbols-outlined">
                          pin_drop
                        </span>
                      </span>
                      <span className="fcontact__content">
                      https://bonestudio.online/
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom footer__bottom__two">
            <p>
              Copyright &copy;2023{" "}
              <Link to="/" className="intellicon">
                Intellicon
              </Link>
              . Designed By{" "}
              <Link
                to="https://themeforest.net/user/pixelaxis"
                className="theme"
              >
                Pixelaxis
              </Link>
            </p>
            <ul className="footer__bottom__link">
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/support">Privacy policy</Link>
              </li>
              <li>
                <Link to="/support">Terms of condition</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* container */}
      {/* footer mask */}
      <div className="footer__mask">
        <img src={box_element} alt="mask" className="img-fluid" />
      </div>
      {/* footer mask */}
    </footer>
  );
};

export default FooterFour;
