import Breadcrumb from "./Breadcrumb";
import SmallBanner from "./SmallBanner";

const Banner = () => {
  return (
    <SmallBanner title="About us">
      {/* Breadcrumb */}
      <Breadcrumb
        breadcrumbs={[
          ["Home", "/"],
          ["About us", "/"],
        ]}
      />
    </SmallBanner>
  );
};

export default Banner;
