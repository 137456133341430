import "bootstrap/dist/css/bootstrap.min.css";

// Material Symbols
import "./public/vendor/fonts/google-font.css";

// animation css
import "./public/vendor/animation/animate.css";

// slick css
import "slick-carousel/slick/slick.css";

//main css
import "./styles/globals.scss";
import { useEffect } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import IndexLayout from "./pages/IndexLayout";
import Signin from './pages/signin'
import Signup from './pages/signup'
import Notfound from './pages/Notfound'
import Edit from "./pages/products/Edit";
import AboutLayout from "./pages/AboutLayout";
import PricingLayout from "./pages/PricingLayout1";
import Transmomy from "./pages/products/Transmomy";
import MyMathLayout from "./pages/products/MyMathLayout";
import ProfitRatio from "./pages/products/ProfitRatio";
import AllProjectLayout from "./pages/Allproject/Allproject";
import Details from "./pages/Allproject/details";
import CompleteWord from "./components/Emg/CompleteWord";
import Eng from "./pages/products/Eng";
import MathQuestionsLayout from "./pages/products/MathQuestions";

const router = createBrowserRouter(
  createRoutesFromElements(

    <Route>
      <Route path="/" element={<IndexLayout />} />
      <Route path="signin" element={<Signin />} />
      <Route path="signup" element={<Signup />} />
      <Route path="edit" element={<Edit />} />
      <Route path="about" element={<AboutLayout />} />
      <Route path="pricing" element={<PricingLayout />} />
      <Route path="Transmomy" element={<Transmomy />} />
      <Route path="Math" element={<MyMathLayout />} />
      <Route path="ProfitRatio" element={<ProfitRatio />} />
      <Route path="AllProject" element={<AllProjectLayout />} />
      <Route path="Details/:id" element={<Details />} />
      <Route path="CompleteWord" element={<CompleteWord />} />
      <Route path="Eng" element={<Eng />} />
      <Route path="MathQuestions" element={<MathQuestionsLayout />} />

      <Route path="*" element={<Notfound />} />

    </Route>

  )
)

function App() {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>

  );
}

export default App;
