import { Link} from 'react-router-dom'
import error from "../public/img/signin/error.png";

export default function Notfound() {
  return (
    <section className="sigin__page error__page bg__white">
      <div className="container">
        <div className="signin__wrapper">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="error__wrapper">
                <div className="thumb">
                  <img src={error} alt="img" className="img-fluid" />
                </div>
                <div className="error__contnet">
                  <h2>Oops! Page Not Found</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <Link to="/" className="cmn--btn">
                    <span>Back To Home</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

