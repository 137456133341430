import NavBar from "../components/navBar/NavBar";
import Preloader from "../components/preloader/Preloader";
import Pricing from "../components/pricing/Pricing1";
import Banner from "../components/pricing/Banner";

const pricingLayout = () => {
  
  return (
    <>  
    <Preloader/>
    <NavBar/>
    <Banner/>
    <Pricing/>

    </>
  );
};

export default pricingLayout;
