import { createContext, useReducer, useEffect, useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useCollection } from '../hooks/useCollection'

export const AuthContext = createContext()

export const authReducer = (state, action) => {

  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null }
    case 'AUTH_IS_READY':
      return { ...state, user: action.payload, authIsReady: true }
      case 'AUTH_IS_NOT_READY':
      return { ...state, user: null, authIsReady: true }
    case 'EDIT_OUT_WORDS':
      return { ...state, wordsOut: action.payload }
    case 'EDIT_IN_WORDS':
      return { ...state, wordsIn: action.payload }
    case 'EDIT_OUT_CASE':
      return { ...state, costOut: action.payload }
    case 'EDIT_IN_CASE':
      return { ...state, costsIn: action.payload }
    case 'EDIT_ALL_CASE':
      return { ...state, costAll: action.payload }
    case 'EDIT_ALL':
      return { ...state, wordsOut: action.payload.wordsOut,
        wordsIn: action.payload.wordsIn,
        costOut: action.payload.costOut,
        costIn: action.payload.costIn,
        costAll: action.payload.costAll}
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    wordsOut: 0,
    wordsIn: 0,
    costOut: 0,
    costIn: 0,
    costAll: 0
  })
  const [idUaser, setIdUser] = useState("")
  const { documents } = useCollection("Users", idUaser)

  useEffect(() => {
    if (!state.authIsReady) {
      projectAuth.onAuthStateChanged(user => {
        if (user) {
          console.log("123123")
          setIdUser(user.uid)
          dispatch({ type: 'AUTH_IS_READY', payload: user })
        }else{
          dispatch({ type: 'AUTH_IS_NOT_READY' })

        }
      })
    } else {
      if(documents){
        console.log("asdasd")

        dispatch({
        type: 'EDIT_ALL', payload: {
          wordsOut: documents[0].wordsOut,
          wordsIn: documents[0].wordsIn,
          costOut: documents[0].costOut,
          costIn: documents[0].costIn,
          costAll: documents[0].costAll
        }
      })
    }

    }
  }, [documents])

  console.log('AuthContext state:', state)

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )

}