import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBGNllsOY67SHNeKSV4BfSHo47qcpAqSwY",
  authDomain: "bone-ai.firebaseapp.com",
  projectId: "bone-ai",
  storageBucket: "bone-ai.appspot.com",
  messagingSenderId: "160955763485",
  appId: "1:160955763485:web:d3ed7056186ab4d8026ff4",
  measurementId: "G-D6XLFFHWJK"
};



// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }