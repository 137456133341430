import { Link} from 'react-router-dom'
import Preloader from "../preloader/Preloader";
import { useEffect, useState } from "react";
import { useSignup } from '../../hooks/useSignup'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
const SignupForm = () => {
  const navigate = useNavigate();

  const [showPro, setShowPro] = useState(false);
  const [firestName, setFirestName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, isPending, error } = useSignup()
  const { user } = useAuthContext();

  useEffect(()=>{
    if(user){
      
      return navigate("/AllProject");
    }
  },[user])
  const handelChangFerstName = (e) => {
    setFirestName(e.target.value)
  }
  const handelChangLastName = (e) => {
    setLastName(e.target.value)
  }

  const handelChangEmail = (e) => {
    setEmail(e.target.value)
  }

  const handelChangPassword = (e) => {
    setPassword(e.target.value)
  }

  const CreateAcount = async(e) => {
    e.preventDefault();
    const nameUser = firestName + " " + lastName;
    console.log(nameUser)
    await signup(email, password, nameUser)
    setShowPro(false)

  }

  return (
    <form onSubmit={CreateAcount}>
      <Preloader />
      {showPro && <Preloader alowTimer={false} />}

      <div className="row g-4">
        <div className="col-lg-6">
          <div className="form__grp">
            <label htmlFor="firstname">First Name</label>
            <input
              onChange={handelChangFerstName}
              type="text"
              id="firstname"
              placeholder="Enter First Name..."
              required
              value={firestName}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form__grp">
            <label htmlFor="lastname">Last Name</label>
            <input
              onChange={handelChangLastName}
              type="text"
              id="lastname"
              placeholder="Enter Last Name..."
              required
              value={lastName}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form__grp">
            <label htmlFor="emailid">Enter YOur Email ID</label>
            <input
              type="email"
              id="emailid"
              placeholder="Enter Your Email..."
              required
              onChange={handelChangEmail}
              value={email}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form__grp">
            <label htmlFor="paswords">Enter Your Password</label>
            <input
              type="text"
              id="paswords"
              placeholder="Enter Your Password..."
              required
              onChange={handelChangPassword}
              value={password}
            />
          </div>
        </div>
      </div>
      <p className="accout">
        Do you have an account? <Link to="/signin">Signin</Link>
      </p>
      <button type={"submit"} className="cmn--btn border-0" >
        <span>Signup</span>
      </button>
    </form>
  );
};

export default SignupForm;
