import Banner from "../../components/AllProjects/show/Banner";
import BlogDetailsMain from "../../components/AllProjects/show/BlogDetailsMain";
import studiesData from "../../data/studiesData";
import { useEffect, useState  } from "react";
import { useParams } from 'react-router-dom';
import NavBar from "../../components/navBar/NavBar";
import Preloader from "../../components/preloader/Preloader";
export default function Details() {
  let { id } = useParams();

  const [opj,setopj]=useState(studiesData[id])

  
  return (
    <>
     <Preloader/>
    <NavBar/>
      {/*Banner section */}
      <Banner opj={opj}/>

      {/*Blog Details Main section */}
      <BlogDetailsMain opj={opj} />
    </>
  );
}
