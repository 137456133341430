import { useState, useEffect } from "react";
import { generateMeta } from '../../API/controllers/openaiController'
import Preloader from '../preloader/Preloader'
import list from '../../data/data'
import { useAuthContext } from "../../hooks/useAuthContext";
import {useWord} from '../../hooks/useWord'

import { useFirestore } from "../../hooks/useFirestore";
const MathQuestions = () => {
    const [pre, setPre] = useState(false);
    const [languages, setLanguages] = useState(list);

    const [outInput, setOutInput] = useState("");

    const [countRows2, setCountRows2] = useState(4);

    const [language, setlanguage] = useState("English")
    const { wordsIn,wordsOut,costIn,costOut,costAll,user } = useAuthContext();

    const { EditWordOut,EditWordIn,EditAllCost,EditCostIn,EditCostOut } = useWord(wordsIn,wordsOut,costIn,costOut,costAll);
    const{editDocument}=useFirestore("Users")
    const [wordsLength, setWordsLength] = useState(0)
    const [EngWords, setEngWords] = useState([]);
    const [EngWord, setEngWord] = useState('');
    const [academicLevel, setAcademicLevel] = useState('');
    const [numberQuestions, setNumberQuestions] = useState('');
    useEffect(() => {
       
        const Line = outInput.trim().split('\n');
        if (Line.length > 4) {
            setCountRows2(Line.length);
        } else {
            setCountRows2(4);
        }
    }, [outInput])
    const deleteWord = (index) => {
        setEngWords((prevWords) => {
            const updatedWords = [...prevWords];
            updatedWords.splice(index, 1);
            return updatedWords;
        });
    };




    return (
        <section className="banner__section banner__section__four  bannerbg">
            <div className="container">
                <div className="banner__wrapper">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <div className="banner__content">
                                <div className="content__box text-white pb-5">
                                    {pre && <Preloader alowTimer={false} />}
                                    
                                    <div className="row bg-white">
                                            {EngWords.map((item, index) => (
                                                <h5 key={index} className="col-3 text-black">
                                                    <button type="button" className="btn-close" onClick={() => deleteWord(index)}></button>
                                                    {item}
                                                </h5>
                                            ))}
                                        </div>
                                        <div className="row">
                                        <input type="text" className="text-black col-3"  value={EngWord} onChange={(e) => setEngWord(e.target.value)} />
                                        <button className="cmn--btn col-2" onClick={() => {
                                            if(EngWord!=""){
                                            setEngWords((prevWords) => [...prevWords, EngWord]);
                                            setEngWord('');
                                            }
                                        }} ><span> add word </span></button>
                                        </div>
                                        <br/>

                                        <span>Academic Level </span>
                                        <input type="text" placeholder="Academic Level" className="text-black col-3" value={academicLevel} onChange={(e)=>{
                                            setAcademicLevel(e.target.value)
                                        }}/>
                                    <br/>
                                    <br/>

                                    <span>Number Of Questions  </span>
                                    <input type="number" placeholder="Number Of Questions" className="text-black col-3" value={numberQuestions} onChange={(e) => {
                                        setNumberQuestions(e.target.value)
                                    }} />
                                    <br />
                                    <span>Select the language you want to display the output in</span>

                                    <button className="cmn--btn m-4" type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <span> {language}</span>
                                    </button>
                                    <br />

                                    <button className="cmn--btn" type="submit" onClick={async () => {
                                        let w=""
                                        EngWords.map((item,index)=>{
                                            w=w+index+" , ";
                                        })
                                        setPre(true)
                                        const str = `I want ${numberQuestions} mathematics questions for grade ${academicLevel} on the following topics ${w} in ${language}`;
                                        let val = await generateMeta(str)
                                        setOutInput(val)

                                        const words2 = val.trim().split(' ');

                                        await EditWordIn(wordsLength)
                                        await EditWordOut(words2.length)
                                        let costInNow = ((((1000 * wordsLength) / 750) * 0.004) / 10000)
                                        let costOutNow = ((((1000 * words2.length) / 750) * 0.004) / 10000)
                                        let total = costInNow + costOutNow;
                                        console.log(user.uid)
                                        console.log(costOutNow)
                                        console.log(total)

                                        await EditAllCost(total)
                                        await EditCostIn(costInNow)
                                        await EditCostOut(costOutNow)
                                        await editDocument(user.uid, { wordsOut: (words2.length+wordsOut), wordsIn: (wordsLength+wordsIn), costOut: (costOutNow + costOut), costIn: (costInNow+costIn), costAll: (total+costAll) })


                                        setPre(false)
                                    }}>
                                        <span>Get Questions </span>
                                    </button>

                                    <div className="form-outline">
                                        <label className="form-label" htmlFor="textAreaExample"> Questions </label>

                                        <textarea disabled className="form-control" id="textAreaExample1" rows={countRows2} value={outInput}></textarea>
                                        <button className="cmn--btn" onClick={() => {navigator.clipboard.writeText(outInput)}}><span>copy text</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-black " id="exampleModalLabel">all language (you'r select {language})</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row bg-black">
                                {languages.map((item,index) => (
                                    <button key={index} className="cmn--btn col-3" type="submit" data-bs-dismiss="modal" onClick={() => {
                                        setlanguage(item.name)
                                    }}>
                                        <span value={item.name} key={item.code} data-bs-dismiss="modal" >{item.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MathQuestions;
