
import { Link} from 'react-router-dom'

import artificial from "../../public/img/efective/artificial.png";
import light_elements from "../../public/img/elements/light-elements.png";
import checkai from "../../public/img/elements/checkai.png";

function About() {
    return (
        <section className="artificial__section bg__white pt-120 pb__60">
    <div className="container">
      <div className="row align-items-center flex-row-reverse justify-content-between">
        <div className="col-xl-6 col-xl-6 col-lg-6">
          <div className="artificial__content">
            <div className="section__header">
              <h2>We provide most realistic Artificial intelligence</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don&#39;t look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn&#39;t anything embarrassing
                hidden in the middle of text.
              </p>
            </div>
            <div className="artificial__app">
              <div className="articial__items">
                <span className="apptext">App Store</span>
                <span className="apppweek">APP OF THE WEEK</span>
                <span className="date">2023</span>
              </div>
              <div className="articial__items">
                <span className="apptext">Google play</span>
                <span className="apppweek">AWARD WINNER</span>
                <span className="date">2023</span>
              </div>
              <div className="articial__items">
                <span className="apptext">Envato</span>
                <span className="apppweek">AWARD WINNER</span>
                <span className="date">2023</span>
              </div>
            </div>
            <p className="pb__40">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
            <Link href="#0" className="cmn--btn">
              <span>Read More</span>
            </Link>
          </div>
        </div>
        <div className="col-xl-5 col-xl-6 col-lg-6">
          <div className="artificial__thumb">
            <img src={artificial} alt="about" />
          </div>
        </div>
      </div>
    </div>
    <div className="light__element1">
      <img src={light_elements} alt="light" />
    </div>
    <div className="light__element2">
      <img src={light_elements} alt="light" />
    </div>
    <div className="light__element3">
      <img src={light_elements} alt="light" />
    </div>
    <div className="light__element4">
      <img src={light_elements} alt="light" />
    </div>
    <div className="light__element5">
      <img src={light_elements} alt="light" />
    </div>
    <div className="light__checkai">
      <img src={checkai} alt="light" />
    </div>
  </section>
    );
}
export default About;