import MyHeader from "../components/Header/MyHeader";
import NavBar from "../components/navBar/NavBar";
import Preloader from "../components/preloader/Preloader";
import Calculator from "../components/Header/Calculator";
import Footer from "../components/footer/FooterFour";

const IndexLayout = () => {
  
  return (
    <>
    <NavBar/>
    <Preloader/>
    <MyHeader/>

    <Calculator/>

    <Footer/>

    </>
  );
};

export default IndexLayout;
