import Details from "./Details";

const DetailsLeft = ({opj}) => {
  return (
    <div className="service__details__left blog__details__left">
      {/* Details  */}
      <Details opj={opj}/>

    </div>
  );
};

export default DetailsLeft;
