import { useEffect, useState } from "react"

export default function ProfitRatio() {

    const [ListOfPurdicts, setListOfPurdict] = useState([
        { nameProduct:"", Product_price: 0, selling_price: 0, Quantity: 0, profitPercentage: 0, capitalPercentage: 0, profit: 0, profitAll: 0 },
    ]);

    const[upuseEff,SetUpuseEff]=useState(false)
    const [totalProfit,setTotalProfit]=useState(0)
    useEffect(() => {
        let total=0;
        ListOfPurdicts.forEach((item, index) => {
            updateprofitPercentageAtIndex((item.profit / item.Product_price) * 100, index)
            updatecapitalPercentageAtIndex((item.profit / item.selling_price) * 100, index)
            updateprofitAllAtIndex(item.profit * item.Quantity,index)
            //console.log( item.Product_price)
            total+=item.profit * item.Quantity;
        })
        setTotalProfit(total)
    }, [upuseEff])

    const updatenameProductAtIndex = (nameProduct, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], nameProduct: nameProduct };
            return newData;
        });
        SetUpuseEff(!upuseEff)
    };
    const updateProduct_priceAtIndex = (Product_price, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], Product_price: Product_price };
            return newData;
        });
        SetUpuseEff(!upuseEff)
    };

    const updateselling_priceAtIndex = (selling_price, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], selling_price: selling_price };
            return newData;
        });
        SetUpuseEff(!upuseEff)
    };

    const updateQuantityAtIndex = (Quantity, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], Quantity: Quantity };
            return newData;
        });
        SetUpuseEff(!upuseEff)
    };

    const updateprofitPercentageAtIndex = (profitPercentage, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], profitPercentage: profitPercentage };
            return newData;
        });
    };

    const updatecapitalPercentageAtIndex = (capitalPercentage, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], capitalPercentage: capitalPercentage };
            return newData;
        });
    };
    const updateprofitAllAtIndex = (profitAll, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], profitAll: profitAll };
            return newData;
        });
    };
    const updateprofitAtIndex = (profit, index) => {
        setListOfPurdict(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], profit: profit };
            return newData;
        });
        SetUpuseEff(!upuseEff)
    };
    const addNewItem = () => {
        setListOfPurdict(prevData => [
            ...prevData,
            { Product_price: 0, selling_price: 0, Quantity: 0, profitPercentage: 0, capitalPercentage: 0, profit: 0 }
        ]);
    };
    return (
        <>
            {ListOfPurdicts.map((item, index) => (
                <div className="banner__section bannerbg p-4 border-top border-bottom" key={index}>
                    <div className="row g-4 p-4">
                    <div className="col-lg-12 col-xs-12">
                            <div className="form__grp">
                                <label htmlFor="nameProduct" className="col-2">name Product</label>
                                <input
                                    type="text"
                                    id="nameProduct"
                                    placeholder="nameProduct"
                                    className="text-dark col-10"
                                    value={item.nameProduct}
                                    onChange={(event) => {
                                        updatenameProductAtIndex(event.target.value, index);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <div className="form__grp">
                                <label htmlFor="Product_price" className="col-5">Product price</label>
                                <input
                                    type="number"
                                    id="Product_price"
                                    placeholder="Product price"
                                    className="text-dark col-7"
                                    value={item.Product_price}
                                    onChange={(event) => {
                                        updateProduct_priceAtIndex(event.target.value, index);
                                        updateprofitAtIndex(item.selling_price - event.target.value, index);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <div className="form__grp">
                                <label htmlFor="selling_price" className="col-5">selling price</label>
                                <input
                                    type="number"
                                    id="selling_price"
                                    placeholder="selling price"
                                    className="text-dark col-7"
                                    value={item.selling_price}
                                    onChange={(event) => {
                                        updateselling_priceAtIndex(event.target.value, index);
                                        updateprofitAtIndex( event.target.value-item.Product_price, index);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <div className="form__grp">
                                <label htmlFor="Quantity" className="col-5">Quantity</label>
                                <input
                                    type="number"
                                    id="Quantity"
                                    placeholder="Quantity"
                                    className="text-dark col-7"
                                    value={item.Quantity}
                                    onChange={(event) => {
                                        updateQuantityAtIndex(event.target.value, index)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 p-4">
                        <div className="col-lg-6 col-xs-12">
                            <label className="col-12">
                                The percentage of profit from the capital : ({item.profit} / {item.Product_price}) * 100 = {item.profitPercentage}%
                            </label>
                            <label className="col-12">
                                So your profit is  : {item.Product_price} * {item.profitPercentage}% = {item.Product_price * item.profitPercentage / 100} in this Purdict
                            </label>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <label className="col-12">
                                The percentage of profit from the sale : ({item.profit} / {item.selling_price}) * 100 = {item.capitalPercentage}%
                            </label>
                            <label className="col-12">
                                So your profit is  : {item.selling_price} * {item.capitalPercentage}% = {item.selling_price * item.capitalPercentage / 100} in this Purdict
                            </label>
                        </div>
                        <div className="col-lg-12 col-xs-12">
                            <label className="col-6">
                            So they are both equal, so now we multiply by the quantity
                            </label>
                            <label className="col-4">
                                {item.profit} * {item.Quantity} = {item.profitAll}
                            </label>
                            <label className="col-2"  type="submit">
                                <button className="cmn--btn m-4"><span>save</span></button>
                            </label>
                        </div>
                    </div>
                </div>
            ))}
            <div className="banner__section bannerbg p-4">
            <div className="col-lg-12 col-xs-12">

                <button className="cmn--btn m-4" onClick={addNewItem} type="submit">
                    <span> add new Prodact</span>
                </button>
                <span> Total profit is : {totalProfit}</span>
                </div>
            </div>
        </>
    )
}
