import { Link} from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
const SigninForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()
  const { user } = useAuthContext();
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }
 
  useEffect(()=>{
    if(user){
      return navigate("/AllProject");
    }
  },[user])
  return (
    <form  onSubmit={handleSubmit}>
      <div className="row g-4">
        <div className="col-lg-12">
          <div className="form__grp">
            <label htmlFor="emailid">Enter YOur Email ID</label>
            <input
              type="email"
              id="emailid"
              placeholder="Enter Your Email..."
              required
              onChange={(e) => setEmail(e.target.value)}
                    value={email}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form__grp">
            <label htmlFor="paswords">Enter Your Password</label>
            <input
              type="text"
              id="paswords"
              placeholder="Enter Your Password..."
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
        </div>
      </div>
      <div className="forget__right">
        <Link to="3" className="forget">
          Forget password
        </Link>
      </div>
      <p className="accout">
        Do you have an account? <Link to="/signup">Signup</Link>
      </p>
      <button type={"submit"} className="cmn--btn border-0">
        <span>Signin</span>
      </button>
    </form>
  );
};

export default SigninForm;
