import studiesData from "../../data/studiesData";
import BlogCard from "./BlogCard";

function AllProject() {

    return (
        <section className="studies__section bg__white pt-80 pb-80">
        {/* container */}
        <div className="container">
          {/* studies Wrapper */}
          <div className="studies__wrap__one">
            <div className="row g-4 justify-content-center">
              {/* col grid */}
              {studiesData.map((itm) => (
                <div
                  key={itm.id}
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-6"
                >
                  {/* Blog Card */}
                  <BlogCard itm={itm} setOpen={false} />
                </div>
              ))}
            </div>
            
          </div>
          {/* studies Wrapper */}
        </div>
        {/* container */}
      </section>
    );
}
export default AllProject;