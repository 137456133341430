import { useEffect } from "react";
import NewTrans from "../../components/Translate/newTrans";
import NavBar from "../../components/navBar/NavBar";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import Preloader from '../../components/preloader/Preloader'
import Footer from "../../components/footer/FooterFour";

export default function Edit() {
  const { user,authIsReady} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsReady) {
      if (!user) {
        return navigate("/signin");
      }
    }
  }, [user,authIsReady,navigate]);
  return (
    <>
   {!authIsReady&& <Preloader alowTimer={false} />}
      <NavBar />

      <NewTrans />
      <Footer/>

    </>
  );
}
