import { useAuthContext } from './useAuthContext'

export const useWord = (wordsIn,wordsOut,CostIn,CostOut,AllCost) => {

    const { dispatch } = useAuthContext()

    const EditWordOut = async(countLis) => {
        //console.log(countLis)
        //console.log(wordsOut)

        wordsOut = parseInt(wordsOut) + parseInt(countLis);
        //console.log(wordsOut)

        dispatch({ type: 'EDIT_OUT_WORDS', payload: wordsOut })
    }

    const EditWordIn = async(countLis) => {
        //console.log(countLis)
        //console.log(wordsIn)

        wordsIn = parseInt(wordsIn) + parseInt(countLis);
        //console.log(wordsIn)

        dispatch({ type: 'EDIT_IN_WORDS', payload: wordsIn })
    }

    const EditCostIn = async(countLis) => {
        //console.log(countLis)
        //console.log(CostIn)

        CostIn = parseInt(CostIn) + parseInt(countLis);
        //console.log(CostIn)

        dispatch({ type: 'EDIT_IN_CASE', payload: CostIn })
    }

    const EditCostOut =async (countLis) => {
        //console.log(countLis)
        //console.log(CostOut)

        CostOut = parseInt(CostOut) + parseInt(countLis);
        //console.log(CostOut)

        dispatch({ type: 'EDIT_OUT_CASE', payload: CostOut })
    }
    const EditAllCost =async (countLis) => {
        console.log(countLis)
        console.log(AllCost)

        AllCost = parseInt(AllCost) + parseInt(countLis);
        console.log(AllCost)

        dispatch({ type: 'EDIT_ALL_CASE', payload: AllCost })
    }
    return { EditWordOut,EditWordIn,EditAllCost,EditCostIn,EditCostOut }
}