import capbi5 from "../public/img/bog-capabilities/bl6.jpg";
import capbi4 from "../public/img/bog-capabilities/bl9.jpg";
import capbi2 from "../public/img/bog-capabilities/capabi2.jpg";
import capbi3 from "../public/img/bog-capabilities/capabi3.jpg";
import capbi1 from "../public/img/bog-capabilities/capbi1.jpg";

const studiesData = [
  {
    id: 0,
    title: "Edit Word in arabic",
    desc:'Arabic Correction Application is an application that aims to assist users in correcting linguistic and spelling errors in Arabic texts.',
    descAll:
    `Arabic Correction Application is an application that aims to assist users in correcting linguistic and spelling errors in Arabic texts. This application is distinguished by its ability to check texts, identify grammatical and spelling errors, and provide suggestions for correcting them.

    The language correction function in this app is based on artificial intelligence and natural language processing technologies. The application is programmed to understand Arabic grammar, spelling, and grammar, and thus can effectively identify and correct common errors.
    
    Among the basic features of the Arabic Correction app are:
    
    1. Automatic correction: The application analyzes texts and automatically identifies linguistic and spelling errors, such as errors in grammar, parsing, syntax, spelling, and others.
    
    2. Automatic suggestions: The application provides suggestions to correct the errors detected, which helps the user to learn the correct syntax and improve his level in the Arabic language.
    
    3. Grammar check: The application checks the correctness of grammatical rules and syntax in the text, such as word order, harmony and agreement between the different parts of the sentence.
    
    4. Spelling correction: The application identifies spelling errors in the texts and provides corrections to them, such as misspelled words or errors in the use of`,
   link:"Edit",
    img: capbi1,
    tag: "Arabic language correction, grammar correction, spelling correction, linguistic accuracy, proofreading, Arabic language editing, language accuracy, language refinement, Arabic language refinement, language error detection, Arabic language improvement, language polishing, Arabic language suggestions",
  },
  {
    id: 1,
    title: "mother of translator",
    desc:`A highly accurate translation application is a software designed to provide precise translations between languages`,
    descAll: `A highly accurate translation application is a software designed to provide precise translations between languages. This application aims to overcome language barriers and enable seamless communication by ensuring the accuracy and fidelity of the translated content.

    The key features of a precise translation application include:
    
    1. Accurate Translations: The application utilizes advanced algorithms and machine learning techniques to ensure accurate translations. It takes into account grammar, syntax, context, and idiomatic expressions to produce high-quality translations.
    
    2. Multiple Language Support: The application supports a wide range of languages, allowing users to translate text from one language to another. It can handle various language pairs, including Arabic to English and vice versa.
    
    3. Contextual Understanding: The application goes beyond literal translations by considering the context of the text. It takes into account the intended meaning and ensures that the translated content accurately conveys the original message.
    
    4. Real-Time Translation: Some translation applications offer real-time translation capabilities, allowing users to translate conversations or speech on the go. These applications can be particularly useful for travelers or in multilingual meetings.
    
    5. User-Friendly Interface: A precise translation application typically offers a user-friendly interface, making it easy to input text, select languages, and view the translated output. It may also provide additional features like voice input, text-to-speech, or offline translation.
    
    6. Regular Updates and Improvements: To maintain accuracy and keep up with language advancements, the application developers regularly update the translation algorithms and databases. This ensures that the translations provided are up to date and reflect the evolving nature of languages.
    
    It's important to note that while translation applications strive for accuracy, there may still be instances where nuanced or complex translations require human intervention. However, a precise translation application serves as a valuable tool for individuals, businesses, and organizations seeking reliable and accurate translations in a convenient and efficient manner.`,
    img: capbi2,
    tag: "Accurate translation, precise translation, reliable translation, language translation, language accuracy, language fidelity, translation accuracy, translation quality, language interpretation, language conversion, language localization, language proficiency",
    link:"Transmomy"
  },
  {
    id: 2,
    title: "FAST Math Solutions",
    desc:`An all-encompassing problem-solving application is a versatile software designed to address a wide range of problem types and provide comprehensive solutions`,
    descAll: `An all-encompassing problem-solving application is a versatile software designed to address a wide range of problem types and provide comprehensive solutions. This application aims to assist users in solving various problems across different domains by offering a diverse set of tools and techniques.

    The key features of an all-inclusive problem-solving application include:
    
    1. Problem Classification: The application categorizes problems into different types, such as mathematical, scientific, logical, programming, or optimization problems. This classification helps users identify the appropriate tools and methods for solving specific problem types.
    
    2. Algorithmic Solutions: The application incorporates a collection of algorithms and problem-solving techniques tailored to handle different problem types. These algorithms are designed to efficiently analyze, process, and solve problems based on their specific characteristics and requirements.
    
    3. Input Flexibility: The application allows users to input problem data in various formats, such as text, numbers, equations, or diagrams. It accommodates different problem representations to ensure compatibility with a wide range of problem-solving scenarios.
    
    4. Step-by-Step Guidance: The application provides step-by-step guidance and explanations throughout the problem-solving process. It breaks down complex problems into manageable steps, enabling users to understand and follow a logical problem-solving approach.
    
    5. Multiple Solution Approaches: The application offers multiple solution approaches for a given problem type. It may provide heuristic methods, numerical methods, symbolic computation, or machine learning-based approaches, allowing users to choose the most suitable method for their specific problem.
    
    6. Customizability: The application often allows users to customize certain parameters or settings to tailor the problem-solving process to their specific needs. This flexibility enhances the adaptability of the application and ensures compatibility with a variety of problem scenarios.
    
    7. Result Presentation: The application presents the solutions in a clear and concise manner, providing relevant details, visualizations, or explanations where necessary. It aims to make the solutions easily understandable and interpretable by users.
    
    8. Regular Updates and Expansion: To stay relevant and address emerging problem types, the application developers frequently update and expand the application's capabilities. This includes incorporating new algorithms, problem domains, and solution techniques to cater to evolving user needs.
    
    An all-encompassing problem-solving application serves as a valuable tool for students, professionals, researchers, and anyone who encounters diverse problem types across different fields. By offering comprehensive solutions and a range of problem-solving methods, the application simplifies and streamlines the process of finding solutions for a wide array of problem types.`,
    img: capbi3,
    tag: "Problem-solving, versatile problem solver, diverse problem types, comprehensive solutions, algorithmic solutions, multiple solution approaches, problem classification, efficient problem analysis, logical problem-solving, mathematical problem-solving, scientific problem-solving, programming problem-solving, optimization problem-solving",
    link:"Math"
  },
  {
    id: 3,
    title: "AI-powered Personalized Learning system",
    desc:"A profit ratio calculator application is a software designed to calculate and analyze profit ratios for individuals",
    descAll: `A profit ratio calculator application is a software designed to calculate and analyze profit ratios for individuals. This application aims to provide users with a quick and accurate way to determine the profitability of their operations or investments.

    The key features of a profit ratio calculator application include:
    
    1. Input of Financial Data: The application allows users to input relevant. It also provide options for specifying different types of profit, such as gross profit, or net profit.
    
    2. Calculation of Profit Ratios: Once the financial data is inputted, the application performs calculations to determine various profit ratios. Commonly calculated ratios include gross profit margin.
    
    3. User-Friendly Interface: A profit ratio calculator application typically features a user-friendly interface that simplifies the input of financial data and the interpretation of calculated ratios. Clear instructions, tooltips, or help sections may be included to assist users in navigating the application effectively.
    
    A profit ratio calculator application serves as a valuable tool for business owners,  or individuals looking to evaluate the profitability of their ventures. By providing accurate and comprehensive profit ratio calculations, it enables users to assess financial performance, make informed decisions, and identify areas for improvement in order to enhance overall profitability.`,
    img: capbi4,
    tag: "profit ratio calculator, profitability assessment, financial performance evaluation, profit margin calculation, net profit margin",
    link:"ProfitRatio"
  },

];

export default studiesData;
