import { useEffect, useState } from "react";
import { generateMeta } from '../../API/controllers/openaiController';
import Preloader from '../preloader/Preloader';

import { useWord } from '../../hooks/useWord';
import { useAuthContext } from "../../hooks/useAuthContext";

import { useFirestore } from "../../hooks/useFirestore";

const CompleteWord = () => {
    const { wordsIn,wordsOut,costIn,costOut,costAll,user } = useAuthContext();
    const { EditWordOut,EditWordIn,EditAllCost,EditCostIn,EditCostOut } = useWord(wordsIn,wordsOut,costIn,costOut,costAll);
    const{editDocument}=useFirestore("Users")
    const [wordsLength, setWordsLength] = useState(0)

    const [pre, setPre] = useState(false);

    const [EngWords, setEngWords] = useState([]);
    const [EngWord, setEngWord] = useState('');
    const [countRows, setCountRows] = useState(4);

    const [lines,setLine]=useState("")

    const [outInput, setOutInput] = useState("");

    const deleteWord = (index) => {
        setEngWords((prevWords) => {
            const updatedWords = [...prevWords];
            updatedWords.splice(index, 1);
            return updatedWords;
        });
    };
    useEffect(()=>{

        let value = lines.replace("  ", " ");
        

        const words2 = value.trim().split(' ');
        setWordsLength(words2.length)
        

        
        
        setLine(value)
        const Line = value.trim().split('\n');
        if (Line.length > 4) {
            setCountRows(Line.length);
        } else {
            setCountRows(4);
        }
    },[lines])
    return (
        <>
            <section className="banner__section banner__section__four  bannerbg">
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                <div className="banner__content">
                                    <div className="content__box text-white pb-5">
                                    {pre && <Preloader alowTimer={false} />}

                                        <div className="row bg-white">
                                            {EngWords.map((item, index) => (
                                                <h5 key={index} className="col-3 text-black">
                                                    <button type="button" className="btn-close" onClick={() => deleteWord(index)}></button>
                                                    {item}
                                                </h5>
                                            ))}
                                        </div>
                                        <div className="row">
                                        <input type="text" className="text-black col-3"  value={EngWord} onChange={(e) => setEngWord(e.target.value)} />
                                        <button className="cmn--btn col-2" onClick={() => {
                                            if(EngWord!=""){
                                            setEngWords((prevWords) => [...prevWords, EngWord]);
                                            setEngWord('');
                                            }
                                        }} ><span> add word </span></button>
                                        </div>

                                        
                                        <div className="form-outline">
                                            <label className="form-label" for="textAreaExample">Enter the text to complete the sentences with the words from the box </label>

                                            <textarea onChange={(e)=>{ setLine(e.target.value)}} className="form-control" value={lines} id="textAreaExample1" rows={countRows}></textarea>
                                        </div>
                                        <button className="cmn--btn m-4" type="submit" onClick={async () => {
                                            setPre(true)
                                            let str="Complete the sentences With Words from the box: ("
                                            EngWords.map((item)=>{
                                                str+=item+" "
                                            })
                                            str+=" )"
                                            str+=lines
                                            let val = await generateMeta(str)
                                            setOutInput(val)
                                            const words2 = val.trim().split(' ');

                                            await EditWordIn(wordsLength)
                                            await EditWordOut(words2.length)
                                            let costInNow = ((((1000 * wordsLength) / 750) * 0.004) / 10000)
                                            let costOutNow = ((((1000 * words2.length) / 750) * 0.004) / 10000)
                                            let total = costInNow + costOutNow;
                                            console.log(user.uid)
                                            // console.log(costOutNow)
                                            // console.log(total)

                                            await EditAllCost(total)
                                            await EditCostIn(costInNow)
                                            await EditCostOut(costOutNow)
                                            await editDocument(user.uid, { wordsOut: (words2.length + wordsOut), wordsIn: (wordsLength + wordsIn), costOut: (costOutNow + costOut), costIn: (costInNow + costIn), costAll: (total + costAll) })
                                            setPre(false)

                                        }} >
                                            <span>answer the questions </span>
                                        </button>
                                        <div className="form-outline">
                                            <label className="form-label" for="textAreaExample">  Answer  </label>

                                            <textarea disabled className="form-control" id="textAreaExample1" rows={countRows} value={outInput}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CompleteWord;
