import NavBar from "../components/navBar/NavBar";
import Preloader from "../components/preloader/Preloader";
import About from "../components/about/About1";
import Banner from "../components/about/Banner";
import Footer from "../components/footer/FooterFour";

const AboutLayout = () => {
  
  return (
    <>  
    <Preloader/>
    <NavBar/>
    <Banner/>
    <About/>
    <Footer/>

    </>
  );
};

export default AboutLayout;
