import Breadcrumb from "./Breadcrumb";
import SmallBanner from "./SmallBanner";

const Banner = ({opj}) => {
  return (
    <SmallBanner title={opj.title}
    breadcrumbs={[
          ["Home", "/"],
          ["All Projects", "/AllProject"],
          [opj.title, "/"],
        ]}
    />
  );
};

export default Banner;
