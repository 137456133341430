import { Link } from 'react-router-dom'
import { useState } from "react";
import { useAuthContext } from '../../hooks/useAuthContext';


const NavItems = ({ active, setActive }) => {
  const [showDropdown, setShowDropdown] = useState("");
  const [imgActive, setImgActive] = useState("");
  const { user, wordsIn, wordsOut, costAll } = useAuthContext();

  const handleShowDropdown = (id) => {
    if (showDropdown === id) {
      setShowDropdown("");
    } else {
      setShowDropdown(id);
    }
  };

  const handleMouseOver = (id) => {
    setImgActive(id);
  };

  return (
    <ul className={`main-menu ${active && "active"}`}>
      {/* comp */}
      <li onClick={() => handleShowDropdown("home")}>
        <Link to="URL:void(0)" className="d-flex">
          <li className="chnage__imgmenu">

            <span
              key="1"
              className={`image-small-list-item ${imgActive === "index" && "active"}`}
              onMouseOver={() => handleMouseOver("index")}
            >
              <Link to="/" onClick={() => setActive(false)}>
                Home
              </Link>
            </span>
          </li>


        </Link>

      </li>

      <li onClick={() => handleShowDropdown("About")}>
        <Link to="URL:void(0)" className="d-flex">
          <li className="chnage__imgmenu">

            <span
              key="1"
              className={`image-small-list-item ${imgActive === "about" && "active"
                }`}
              onMouseOver={() => handleMouseOver("about")}
            >
              <Link to="/about" onClick={() => setActive(false)}>
                About
              </Link>
            </span>
          </li>


        </Link>

      </li>


      <li className="grid__style" onClick={() => handleShowDropdown("Pricing Page")}>
        <Link to="URL:void(0)" className="d-flex">
          <li className="chnage__imgmenu">

            <span
              key="1"
              className={`image-small-list-item ${imgActive === "Pricing Page" && "active"
                }`}
              onMouseOver={() => handleMouseOver("Pricing Page")}
            >
              <Link to="/pricing" onClick={() => setActive(false)}>
                Pricing
              </Link>
            </span>
          </li>


        </Link>

      </li>

      <li className="grid__style">
        <Link
          href="URL:void(0)"
          className="d-flex"
          onClick={() => handleShowDropdown("products")}
        >
          <span>products</span>
          <span className="icons">
            <i className="material-symbols-outlined">expand_more</i>
          </span>
        </Link>
        <ul className={`sub-menu ${showDropdown === "products" ? "d-block" : ""}`}>
          {[
            ["All Project", "/AllProject"],
            ["Edit Word in arabic", "/Edit"],
            ["mother of translator", "/Transmomy"],
            ["FAST Math Solutions", "/Math"],
            ["Math Of Questions", "/MathQuestions"],
            ["Profit Ratio Calculator", "/ProfitRatio"],
            ["Complete the sentences in English", "/Eng"]

          ].map(([itm, url], i) => (
            <li key={`ssa${i}`} className="subtwohober">
              <Link to={url} onClick={() => setActive(false)}>
                {itm}
              </Link>
            </li>
          ))}
        </ul>
      </li>

      {user && <li className="grid__style">
        <Link
          href="URL:void(0)"
          className="d-flex"
          onClick={() => handleShowDropdown("welcome")}
        >
          <span>welcome  {user.displayName}</span>
          <span className="icons">
            <i className="material-symbols-outlined">expand_more</i>
          </span>
        </Link>
        <ul className={`sub-menu ${showDropdown === "welcome" ? "d-block" : ""}`}>
          {[
            [`words out : ${wordsOut}`, "/#"],
            [`words in  : ${wordsIn}`, "/#"],
            [`cost      : ${costAll}`, "/#"],

          ].map(([itm, url], i) => (
            <li key={`ssa${i}`} className="subtwohober">
              <Link onClick={() => setActive(false)}>
                {itm}
              </Link>
            </li>
          ))}
        </ul>
      </li>

      }

    </ul>
  );
};

export default NavItems;
