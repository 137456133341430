import { useEffect, useState } from "react";
import { generateMeta } from '../../API/controllers/openaiController'
import Preloader from '../preloader/Preloader'
import list from '../../data/data'

import {useWord} from '../../hooks/useWord'
import { useAuthContext } from "../../hooks/useAuthContext";

import { useFirestore } from "../../hooks/useFirestore";
const InputText = () => {
    const { wordsIn,wordsOut,costIn,costOut,costAll,user } = useAuthContext();

    const [showError, setShowError] = useState(false);
    const [pre, setPre] = useState(false);

    const [textInput, setTextInput] = useState("");

    const [outInput, setOutInput] = useState("");

    const [countRows, setCountRows] = useState(4);

    const [showHide, setShowHide] = useState(false);
    const [language, setlanguage] = useState("English")
    const [languages, setLanguages] = useState(list);
    const [wordsLength, setWordsLength] = useState(0)

    const { EditWordOut,EditWordIn,EditAllCost,EditCostIn,EditCostOut } = useWord(wordsIn,wordsOut,costIn,costOut,costAll);

    const{editDocument}=useFirestore("Users")
    const handleChange = (event) => {
        setTextInput(event.target.value);
    };
    useEffect(()=>{

        let value = textInput.replace("  ", " ");
        const words2 = value.trim().split(' ');
        setWordsLength(words2.length)
        if (words2.length <= 750) {
            setTextInput(value);
            setShowError(false);
        } else {
            setShowError(true);
        }
        const Line = value.trim().split('\n');
        if (Line.length > 4) {
            setCountRows(Line.length);
        } else {
            setCountRows(4);
        }
    },[textInput])

    return (
        <>
            <section className="banner__section banner__section__four  bannerbg" >
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                <div className="banner__content">
                                    <div className="content__box text-white pb-5">
                                        {pre && <Preloader alowTimer={false} />}

                                        <div className="form-outline">
                                            <label className="form-label" for="textAreaExample">Input Text To Translate</label>

                                            <textarea onChange={handleChange} className="form-control" id="textAreaExample1" rows={countRows}></textarea>
                                        </div>
                                        <button className="cmn--btn m-4" type="submit" onClick={async () => {
                                            setPre(true)
                                            let val = await generateMeta(`Translate the following text to ${language}:"${textInput}"`)
                                            setOutInput(val)
                                            const words2 = val.trim().split(' ');

                                            await EditWordIn(wordsLength)
                                            await EditWordOut(words2.length)
                                            let costInNow=((((1000*wordsLength)/750)*0.004)/10000)
                                            let costOutNow=((((1000*words2.length)/750)*0.004)/10000)
                                            let total=costInNow+costOutNow;
                                            console.log(user.uid)
                                           // console.log(costOutNow)
                                           // console.log(total)

                                            await EditAllCost(total)
                                           await EditCostIn(costInNow)
                                           await EditCostOut(costOutNow)
                                           await editDocument(user.uid, { wordsOut: (words2.length+wordsOut), wordsIn: (wordsLength+wordsIn), costOut: (costOutNow + costOut), costIn: (costInNow+costIn), costAll: (total+costAll) })
                                            setPre(false)
                                            
                                        }}>
                                            <span>Transmomy To </span>
                                        </button>

                                        <button className="cmn--btn m-4" type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <span> {language}</span>
                                        </button>


                                        {showError &&
                                            <div className="typography__items">

                                                <h5 className="text-danger">
                                                    The maximum limit is 750 words. You must develop your account if you want more than that
                                                </h5>
                                            </div>

                                        }
                                        <div className="form-outline">
                                            <label className="form-label" for="textAreaExample"> Mother Of Translator </label>

                                            <textarea disabled className="form-control" id="textAreaExample1" rows={countRows} value={outInput}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 text-black " id="exampleModalLabel">all language (you'r select {language})</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row bg-black">

                                    {languages.map((item) => (
                                        <button className="cmn--btn col-3" type="submit" onClick={() => {
                                            setlanguage(item.name)

                                            setShowHide(!showHide)
                                        }}>
                                            <span  value={item.name} key={item.code} data-bs-dismiss="modal" >{item.name}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default InputText;
