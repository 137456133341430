import PopularTags from "./PopularTags";

const DetailsRight = ({TagsList}) => {
  return (
    <div className="service__details__right">
      <div className="common__item">
        {/* Popular Tags */}
        <PopularTags TagsList={TagsList}/>
      </div>
    </div>
  );
};

export default DetailsRight;
