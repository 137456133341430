import NavBar from "../../components/navBar/NavBar";
import Preloader from "../../components/preloader/Preloader";
import AllProject from "../../components/AllProjects/AllProject";
import Banner from "../../components/AllProjects/Banner";
import Footer from "../../components/footer/FooterFour";

const AllProjectLayout = () => {
  
  return (
    <>  
    <Preloader/>
    <NavBar/>
    <Banner/>
    <AllProject/>
    <Footer/>

    </>
  );
};

export default AllProjectLayout;
