import { useEffect } from "react";
import InputText from "../../components/edit/InputText";
import NavBar from "../../components/navBar/NavBar";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import Preloader from '../../components/preloader/Preloader'
import Footer from "../../components/footer/FooterFour";

export default function Edit() {
  const { user ,authIsReady} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsReady) {
      if (!user) {
        console.log("asdasdasd   ")
        return navigate("/signin");
      }
    }
  }, [user, authIsReady]);
  return (
    <>
       {!authIsReady&& <Preloader alowTimer={false} />}

         <NavBar/>

     <InputText/>
     <Footer/>

    </>
  );
}
