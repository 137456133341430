import { useEffect, useState } from "react"
import { useAuthContext } from '../../hooks/useAuthContext';

export default function Calculator() {
    const { words } = useAuthContext();

    const [token, setToken] = useState(1000);
    const [price, setPrice] = useState(0.002);
    const [word, setWord] = useState(750);

    const [mytoken, setMyToken] = useState(500000);
    const [myprice, setMyPrice] = useState(1);
    const [myword, setMyWord] = useState(375000);

    useEffect(() => {
        //setMyWord(words)
        setMyToken((token * myprice) / price)
        setMyWord((mytoken * word) / token)
    }, [token, price, word, mytoken, myprice, myword])
    return (
        <div className="banner__section bannerbg p-4">
            <div className="row g-4 p-4">
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="Tokens" className="col-5">Enter your Tokens</label>
                        <input
                            type="number"
                            id="Tokens"
                            placeholder="Enter your Tokens"
                            className="text-dark col-7"
                            value={token}
                            onChange={(event) => {
                                setToken(event.target.value)


                            }}
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="price" className="col-5">Token price</label>
                        <input
                            type="number"
                            id="price"
                            placeholder="Token price"
                            className="text-dark col-7"
                            value={price}
                            onChange={(event) => {
                                setPrice(event.target.value)


                            }}

                        />
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="Word" className="col-5">number Word</label>
                        <input
                            type="number"
                            id="Word"
                            placeholder="number Word"
                            className="text-dark col-7"
                            value={word}
                            onChange={(event) => {
                                setWord(event.target.value)


                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row g-4 p-4">
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="Tokensget" className="col-5">Tokens you get</label>
                        <input
                            type="number"
                            id="Tokensget"
                            placeholder="Enter your Tokens"
                            className="text-dark col-7"
                            value={mytoken}
                            disabled
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="yourprice" className="col-5">your price</label>
                        <input
                            type="number"
                            id="yourprice"
                            placeholder="Token price"
                            className="text-dark col-7"
                            value={myprice}
                            onChange={(event) => {
                                setMyPrice(event.target.value)


                            }}

                        />
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="form__grp">
                        <label htmlFor="numberWord" className="col-5">number Word</label>
                        <input
                            type="number"
                            id="numberWord"
                            placeholder="number Word"
                            className="text-dark col-7"
                            value={myword}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}