import { useEffect, useState } from 'react';
import { Link} from 'react-router-dom'

const BlogCard = ({ itm, clss = "", setOpen }) => {
  const { id,title, desc, img, tag } = itm;

  const [tagArr,setTagArr]=useState([])
  useEffect(()=>{
    setTagArr(tag.split(","))
  },[])
  return (
    <div className={`capabilities__items ${clss}`}>
      <div className="thumb">
        <img src={img} alt="capabi" className="img-fluid" />
        <button
          className="play__btn video-btn border-0 text-white"
          onClick={() => setOpen(true)}
        >
          <i className="material-symbols-outlined">play_arrow</i>
        </button>
      </div>
      <div className="content">
          {
            tagArr.slice(0, 3).map((item,index)=>(
              <button className="cmn--btn capabilites__btn" key={index}>
          <span >{item}</span>
          </button>
            ))
          }
        <h4>
          {title}
        </h4>
        <p>{desc}</p>
        <Link to={`/Details/${id}`} className="capa__more">
          <span>Read More</span>
          <i className="material-symbols-outlined">east</i>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
