//import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import moon from "../../public/img/moon.png";
import sun from "../../public/img/sun.png";
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';

const MenuRight = ({ clss, setActive, active }) => {
  const [enabled, setEnabled] = useState(false);
  const { user } = useAuthContext();
  const { logout } = useLogout()

  // const { theme, setTheme } = useTheme();

  /* const handleTheme = () => {
     setTheme(theme === "dark" || theme === "system" ? "light" : "dark");
   };*/

  useEffect(() => {
    console.log("user     "+user)
    setEnabled(true)
  
  },[]);

  if (!enabled) return null;

  return (
    <div
      className={`menu__right__components ${clss} d-flex align-items-center`}
    >
      {/* <div className="menu__right__components right__com d-flex align-items-center"> */}
      <div className="menu__components">
        {/* <button className="mode--toggle border" onClick={handleTheme}>
          <img src={theme === "light" ? moon : sun} alt="icon" />
  </button>*/}
       
       
        {!user && <Link to="/signin" className="cmn--btn">
          <span>Signin</span>
        </Link>
        }
        {user && <Link className="cmn--btn" onClick={logout}>
          <span>Logout</span>
        </Link>
        }
         
      </div>
      <div
        className={`header-bar d-lg-none ${active && "active"}`}
        onClick={() => setActive(!active)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MenuRight;
