import { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import blog_details1 from "../../../public/img/bog-capabilities/blog-details1.png";
import { Link ,useNavigate} from "react-router-dom";

const Details = ({ opj }) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const [list,Setlist]=useState([])
  useEffect(()=>{
    let line=opj.descAll.split("\n")
    Setlist(line)
    console.log(line)
  },[])
  const handelClick=()=>{
    navigate(`/${opj.link}`, { replace: true });

  }
  return (
    <>
      {/* TODO: Video Modal  */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="wXNv-x5zVgE"
        onClose={() => setOpen(false)}
      />

      <div className="machine__learning__box mb-5">
        <div className="details__thumb">
          <img src={blog_details1} alt="details" className="img-fluid" />
        </div>
        <div className="content">
          <h2 className="headtext">
            {opj.title}
          </h2>
          {list.map((item,index)=>(
          <p className="text1" key={index}>
            {item}
          </p>
          ))
}
          <button onClick={handelClick} className="cmn--btn m-4">
           <span>
            Try it new
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Details;
