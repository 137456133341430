import { useEffect } from "react";
import ProfitRatio from "../../components/ProfitRatio/ProfitRatio";
import NavBar from "../../components/navBar/NavBar";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import Preloader from '../../components/preloader/Preloader'
import Footer from "../../components/footer/FooterFour";

export default function ProfitRatio1() {
    const { user, authIsReady } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (authIsReady) {
            if (!user) {
                return navigate("/signin");
            }
        }
    }, [user, authIsReady, navigate]);
    return (
        <>
            {!authIsReady && <Preloader alowTimer={false} />}
            <NavBar />

            <section className="banner__section banner__section__four  bannerbg">
                <div className="container">
                    <div className="banner__wrapper">
                                <ProfitRatio />
                    </div>
                </div>
            </section >
            <Footer />

        </>
    );
}
